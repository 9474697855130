<template>
    <div id="login">
        <PasswordReset v-if="showPasswordReset" @close="showPasswordReset = false"></PasswordReset>

        <v-row class="pa-10 text-right" justify="end">
            <v-col>
                <LanguageMenu />
            </v-col>
        </v-row>

        <v-container fluid class="pa-10">
            <v-row>
                <v-card :loading="loading" class="mx-auto my-12" max-width="374" elevation="0">
                    <v-img src="@/assets/equidox-logo.svg"></v-img>
                </v-card>
            </v-row>

            <v-row justify="center">
                <v-col cols="24" sm="6">
                    <v-form @submit.prevent class="loginForm">
                        <v-container>
                            <v-row class="pa-2">
                                <v-col>
                                    <h1>{{ $t("login.signIn") }} </h1>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-text-field outlined v-model.trim="loginForm.email" :rules="[rules.required]"
                                        name="email" :label="$t('login.email')" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-text-field outlined v-model.trim="loginForm.password"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required]" :type="showPassword ? 'text' : 'password'"
                                        name="password" :label="$t('login.password')" class="input-group--focused"
                                        @click:append="showPassword = !showPassword" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-btn type="submit" block x-large @click="loginUser()" class="primary">
                                        {{ $t("login.logIn") }}
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <a @click="fetchPasswordReset">{{
                                        $t("login.forgotPassword")
                                        }}</a>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>

                    <v-container class="loginForm text-center">
                        <v-row>
                            <v-col>{{ $t("login.signInWith") }}</v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn @click="loginGoogle()">
                                    <v-icon small left>
                                        $vuetify.icons.google
                                    </v-icon>
                                    Google
                                </v-btn>
                            </v-col>

                            <v-col>
                                <v-btn @click="loginMicrosoft()">
                                    <v-icon small left>
                                        $vuetify.icons.microsoft
                                    </v-icon>
                                    Microsoft
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
                <!-- comment starting here for divider -->
                <v-divider vertical class="hidden-xs-only"></v-divider>

                <v-col class="pa-10">
                    <v-row class="pa-10 pt-0">
                        <v-col>
                            <!-- content here -->
                            <template>
                                <v-tabs v-model="selectedTab">
                                    <v-tab key="new">
                                        New
                                    </v-tab>
                                    
                                    <v-tab key="last">
                                        7-6-001
                                    </v-tab>

                                    <v-tab key="old">
                                        Beta
                                    </v-tab>
                                </v-tabs>

                                <v-tabs-items v-model="selectedTab">
                                    <v-tab-item key="new">
                                        <v-card>
                                            <v-card-title>
                                                Release Notes: 7-6-201
                                            </v-card-title>

                                            <v-card-text>
                                                Date: January 22, 2025 <br>
                                                <u>Overview:</u> “A minor release with major impact.” <br><br>
                                                We’re all excited about starting 2025 out on a positive note,
                                                and even with this minor version release, we expect it to make a
                                                major Page Editor impact. We’ve improved the way zones are sized and adjusted.  
                                                We’ve improved the way Zone Info is displayed and we’ve solved some errors 
                                                that had an impact on workflow efficiency.  
                                                Take a look at the New UI and experience the changes for yourself!
                                            </v-card-text>
                                            <v-card-subtitle style=""> - The Equidox Team</v-card-subtitle>
                                            
                                        </v-card>
                                    </v-tab-item>

                                    <v-tab-item key="last">
                                        <v-card>
                                            <v-card-title>
                                                Release Notes: 7-6-001
                                            </v-card-title>

                                            <v-card-text>
                                                Date: November 18, 2024 <br>
                                                <u>Overview:</u> “Bountiful Fixes” <br><br>
                                                To end our 2024 Software as a Service (SaaS) release schedule on a full 
                                                and plentiful note, the Equidox team has released its 4th quarter bug fixes
                                                 a few weeks early!  Enjoy nearly two dozen resolved issues, 
                                                 new documentation including a demo video, as well as feature 
                                                 improvements in the List Detector and Table Editor. <br><br>

                                                 Check out the complete list of New Features, Enhancements, and 
                                                 Resolved Issues in the <b>7-6-001: Full Release Notes</b>, which are 
                                                 available at the top of the Equidox Support & Help Bubble.

                                            </v-card-text>
                                            <v-card-subtitle style=""> - The Equidox Team</v-card-subtitle>
                                            
                                        </v-card>
                                    </v-tab-item>

                                    <v-tab-item key="old">
                                        <v-card class="">
                                            <v-card-title class="">Limited Beta Enrollment Now Available!</v-card-title>
                                            <v-card-text class="">

                                                As part of our commitment to continuous improvement Equidox will be
                                                offering a limited Beta
                                                enrollment for our new WCAG 2.1: Reflow-Enabled Output Engine. <br><br>

                                                Be part of the team and experience the difference reflow makes to your
                                                end users.
                                                Our Phase 1 WCAG 2.1: Reflow Engine will be implemented side-by-side
                                                with its time-tested
                                                predecessor and can be selected for each document on a case-by-case
                                                basis.
                                                We know it’s a Beta, so we’ve made it as easy as possible to
                                                participate.<br><br>

                                                Our Phase 1 WCAG 2.1: Reflow participants will have access to: <br>
                                                <ul>
                                                    <li>Reflow for 90% of Equidox zones. We’re still perfecting
                                                        Footnotes, Custom Graphic and Image
                                                        content.</li>
                                                    <li>Content tree reorganization. Your Tag Tree and your Content Tree
                                                        will be in sync. We’re still
                                                        perfecting the Z-Reading Order.</li>
                                                    <li>Consistent behavior between Screen Readers and Text-to-Speech
                                                        readers.</li><br>
                                                </ul>
                                                For more information or to enroll straight away, contact your account
                                                manager or enroll online through
                                                our in-app help menu.

                                            </v-card-text>
                                            <v-card-subtitle style=""> - The Equidox Team</v-card-subtitle>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs-items>
                            </template>
                        </v-col>

                        <!--
						<v-col>
              <v-card class="loginAds">
                Ads
              </v-card>
            </v-col>
						-->
                    </v-row>


                    <!--
          <v-row justify="center">
            <v-col cols="4">
              <a href="https://equidox.co" class="pa-35">
                <v-icon large class="pl-1 ma-5">$vuetify.icons.equidox</v-icon

                >{{ $t("login.equidoxMainPage") }}
              </a>
            </v-col>
            <v-col cols="4">
              <a href="https://equidox.co" class="pa-3">
                <v-icon large class="pl-1 ma-5">$vuetify.icons.equidox</v-icon
                >{{ $t("login.equidoxTutorials") }}
              </a>
            </v-col>
            <v-col cols="4">
              <a href="https://equidox.co" class="pa-3">
                <v-icon large class="pl-1 ma-5">$vuetify.icons.equidox</v-icon
                >{{ $t("login.equidoxNewsBlog") }}
              </a>
            </v-col>
          </v-row>
					-->
                </v-col>

            </v-row>

            <v-row class="pa-10 text-center">
                <v-col>
                    {{ $t("login.dontHaveAnAccount") }}
                    <a href="https://equidox.co/demo"> {{ $t("login.requestADemo") }} </a>
                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import PasswordReset from "@/components/PasswordReset";
import LanguageMenu from "@/components/LanguageMenu";
import { mapGetters, mapActions } from "vuex";
import router from "@/router"
import EventBus from "@/eventBus"
import newLogo from "@/assets/equidox-new-logo.svg" //used on index.html
import background from "@/assets/officeback.jpg" //used on index.html

export default {
	components: {
		PasswordReset,
		LanguageMenu,
	},
	data() {
		return {
			loading: false,
			loginForm: {
				email: "",
				password: "",
			},
			showPassword: false,
			showPasswordReset: false,
            selectedTab: null,
			rules: {
				required: (value) => !!value || "Required.",
				min: (v) => v.length >= 8 || "Min 8 characters",
			},
		};
	},
	computed: {
		...mapGetters({
			authenticated: "user/authenticated",
		})
	},
	watch: {
		/*
			In the case where the user is authenticated, but they go to login page
			if the user is already authenticated, we don't want to sit on this page.
		*/
		'authenticated': function (newVal, oldVal) {
			console.log("authenticated changed.  path is: ", router.currentRoute.path)
			if (this.authenticated === true && router.currentRoute.path === "/login") {
				console.log('user is authenticated moving off login page')
				router.push("/")
			}
		}
	},
	methods: {
		...mapActions({
			login: "user/login",
			loginWithGoogle: "user/loginGoogle",
			loginWithMicrosoft: "user/loginMicrosoft",
			fetchOptions: "application/fetchOptions",
		}),

		toggleForm() {
			this.showLoginForm = !this.showLoginForm;
		},
		togglePasswordReset() {
			this.showPasswordReset = !this.showPasswordReset;
		},
		/*
			load the password reset page from app engine
		*/
		fetchPasswordReset() {
			window.open(process.env.VUE_APP_HOST_URL + '/password-reset', '_self')
		},
		loginUser() {
			console.log('logging in with uid/pwd')
			this.login({
				email: this.loginForm.email,
				password: this.loginForm.password,
			}).then((response) => {
				console.log('login response', response)
				console.log('fetching user statuses')
				this.fetchUserStatuses()
			}).catch((error) => {
				console.log('in error response:', error)
				this.$snackbar.showMessage({ content: this.$t("login.msg.invalidUserPwd"), color: 'error' })
			})
		},
		//we want to call this first instead of going to the action directly from the click
		loginGoogle() {
			console.log('logging in with google')
			this.loginWithGoogle()
				.then((response) => {
					console.log('login with google successful')
				}).catch((error) => {
					console.log('login with google error response', error)
					this.$snackbar.showMessage({ content: this.$t("login.msg.errorLoginGoogle", [error]), color: 'error' })
				})
		},
		fetchUserStatuses() {
			this.fetchOptions()
		},
		loginMicrosoft() {
			console.log('logging in with microsoft')
			this.loginWithMicrosoft()
				.then((response) => {
					console.log('login with microsoft successful')
				}).catch((error) => {
					console.log('login with microsoft error response', error)
					this.$snackbar.showMessage({ content: "Error loging in with Microsoft", color: 'error' })
				})
		},
	},
	mounted() {
		/*
		console.log('in login component checking authentication')
		if(this.authenticated){
			router.push("/")
		}
		*/

		/*
		we need this because the login-google is only part of the authentication process.
		after the auth state change is picked up in main, the backend login happens which tries to auth the user to equidox.
		since it's disconnedted from this login component, we need an event.
		*/
		EventBus.$on('authentication-failed', (msg) => {
			console.log('picking up authentication failed message in login!')
			this.$snackbar.showMessage({ content: this.$t("login.msg.errorLoginGoogle", [msg]), color: 'error' })
		})

		//prevent loading of login page if the user is already authenticated
		if (this.authenticated === true && router.currentRoute.path === "/login") {
			console.log('user is authenticated moving off login page')
			router.push("/")
		}
	},

	beforeDestroy() {
		EventBus.$off('authentication-failed')
	}
}
</script>
<style lang="scss" scoped>
.loginForm {
	max-width: 25rem !important;
	margin: auto !important;
}

.loginAds {
	text-align: center;
	padding: 10px;
	height: 300px;
}</style>
